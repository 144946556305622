import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const resetSlice = createSlice({
  name: 'reset',
  initialState,
  reducers: {
    resetAll: () => initialState,
  },
});

export const { resetAll } = resetSlice.actions;
export default resetSlice.reducer;