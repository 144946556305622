import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getAccountDetails } from '../../services/api/panormix';

export const fetchProfile = createAsyncThunk('profile/fetchProfile', async () => {
    const response = await getAccountDetails();
    return response.data;
});

const profileSlice = createSlice({
    name: 'profile',
    initialState: null,
    reducers: {
        setProfile: (state, action) => {
            return action.payload;
        },
        
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchProfile.fulfilled, (state, action) => {
                return action.payload;
            });
    },
});

export const { setProfile } = profileSlice.actions;

export default profileSlice.reducer;
