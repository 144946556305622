import React, { CSSProperties, useEffect, useState } from "react";
import { Accordion, AccordionBody, AccordionHeader, AccordionItem, Badge, Button, Card, CardBody, CardHeader, CardText, CardTitle, Col, Container, FormFeedback, FormGroup, FormText, Input, InputGroup, InputGroupText, Label, Modal, ModalBody, ModalHeader, Nav, NavItem, NavLink, Popover, PopoverBody, Progress, Row, Spinner, TabContent, TabPane, Table } from 'reactstrap';
import { FaArrowLeft, FaArrowRight, FaDesktop, FaDonate, FaInfoCircle, FaList, FaLock, FaMobile, FaPlus, FaSyncAlt, FaTrash, FaTrashAlt } from "react-icons/fa";
import { TiWarningOutline } from "react-icons/ti";
import IconToExplain from "../../components/ui/IconToExplain";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { setCheckoutFormErrors, updatePayerInfos } from "../../redux/slices/paymentCheckoutSlice";
import { hasAnyErrors } from "../../utils/helper";
import QtyInput from "../../components/form/input/QuantityInput";

interface EventItemProps {
    itemObj: {
        id: number;
        label: string;
        description: string;
        price: string;
    },
    itemFor: 'event' | 'extra';
    onQuantityChange: (p_id: any, ov_id: any, qty: any) => void;
    smallSize?: boolean;
}
const EventItem: React.FC<EventItemProps> = ({ itemObj: ov, itemFor, onQuantityChange, smallSize = false }) => {

    const payment_checkout = useSelector((state: any) => state.checkoutBuilder);
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

    const maxLimitExceeded = (): number => {
        if (itemFor === 'event') {
            return 10;
        } else if (itemFor === 'extra') {
            let totalTickets = 0;

            payment_checkout.checkoutOrder.products.forEach((event: any) => {
                totalTickets += event.quantity;
            });

            return totalTickets
        }
        return 0;
    };

    return (
        <>
            {
                smallSize ?
                    <Row className="product-item" style={{ borderRadius: "20px", paddingTop: "10px" }}>
                        <Col xs={12}>
                            <Row
                                className='d-flex align-items-center justify-content-between w-100'
                            >
                                <Col sm={7} className='d-flex flex-column justify-content-start text-start h-100'>
                                    <div className="product-item-details">
                                        <div

                                            className="product-item-label"
                                            style={{ fontSize: "0.75rem" }}
                                        >
                                            {ov.label}
                                        </div>
                                        <div
                                            className="product-item-description"
                                            style={{ fontSize: "0.5rem" }}
                                        >
                                            {ov.description}
                                        </div>
                                    </div>
                                </Col>

                                <Col sm={3} className="d-flex justify-content-end">
                                    <span
                                        className="product-item-price"
                                        style={{ fontSize: "0.75rem" }}
                                    >
                                        {ov.price} <small>TND</small>
                                    </span>
                                </Col>
                            </Row>
                            <Row
                                className='d-flex align-items-center w-100 mt-1'
                            >
                                <Col sm={2} className="d-flex flex-column align-items-start">


                                </Col>
                                <Col sm={7} className="">

                                </Col>
                                <Col sm={3} className="d-flex justify-content-end">
                                    <QtyInput
                                        quantity={itemFor == "event" ? payment_checkout.checkoutOrder.products.find((e: any) => e.event_id === ov.id)?.quantity || 0 : payment_checkout.checkoutOrder.extras.find((e: any) => e.extra_id === ov.id)?.quantity || 0}
                                        onQuantityChange={(qty) => onQuantityChange(payment_checkout.selectedProducts[0].id, ov.id, qty)}
                                        maxLimit={maxLimitExceeded()}
                                        smallSize
                                    //maxLimit={ov.stock === 0 ? undefined : ov.stock}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row> :

                    <Row className="product-item" style={{ borderRadius: "20px", paddingTop: "10px" }}>
                        <Col xs={12}>
                            <Row
                                className='d-flex align-items-start justify-content-between w-100'
                            >
                                <Col sm={7} className='d-flex flex-column justify-content-start text-start h-100'>
                                    <div className="product-item-details">
                                        <div className="product-item-label">
                                            {ov.label}
                                        </div>
                                        <div className="product-item-description">
                                            {ov.description}
                                        </div>
                                    </div>
                                </Col>

                                <Col sm={3} className="d-flex justify-content-end">
                                    <span
                                        className="product-item-price"
                                    >
                                        {ov.price} <small>TND</small>
                                    </span>
                                </Col>
                            </Row>
                            <Row
                                className='d-flex align-items-center w-100 my-3'
                            >
                                <Col sm={2} className="d-flex flex-column align-items-start">


                                </Col>
                                <Col sm={7} className="">

                                </Col>
                                <Col sm={3} className="d-flex justify-content-end">
                                    <QtyInput
                                        quantity={itemFor == "event" ? payment_checkout.checkoutOrder.products.find((e: any) => e.event_id === ov.id)?.quantity || 0 : payment_checkout.checkoutOrder.extras.find((e: any) => e.extra_id === ov.id)?.quantity || 0}
                                        onQuantityChange={(qty) => onQuantityChange(payment_checkout.selectedProducts[0].id, ov.id, qty)}
                                        maxLimit={maxLimitExceeded()}
                                    //maxLimit={ov.stock === 0 ? undefined : ov.stock}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>

            }
        </>


    )
}

export default EventItem;