
import React, { startTransition } from 'react'
import PButton from '../../components/form/button/Button'
import failure from '../../assets/images/failure.gif'
import { useNavigate } from 'react-router-dom'

const PaymentFailure = () => {
  const navigate = useNavigate();

  const goToPreviousPage = () => {
    startTransition(() => {
      navigate(-1);
    });
  };
  const handleRefresh = () => {
    if (window.top) {
      window.top.location.reload();
    } else {
      console.error("Unable to access the top-level window.");
    }
  };
  return (
    <>
      <div className='shadow-sm p-3 bg-white rounded mt-2' style={{ marginLeft: "25%", marginRight: "25%", height: "" }}>
        <div className="card-body">
          <div className='text-center ' style={{ color: "#D83A52", fontSize: "28px", fontFamily: "Inter", fontWeight: "700" }}>Paiement échoué !</div>
          <div className='text-center d-flex justify-content-center'>
            <img src={failure}></img>
          </div>
          <div className='mt-2' >
            <PButton size='lg' type="submit"
              label="Retour"
              onClick={handleRefresh}
            ></PButton>
          </div>
        </div>


      </div>
    </>
  )
}

export default PaymentFailure
