import React, { useState } from 'react';
import "./QuantityInput.css";

interface QuantityInputProps {
    quantity: number;
    maxLimit?: number;
    minLimit?: number;
    onQuantityChange: (newQuantity: number) => void;
    smallSize?: boolean;
}

const QtyInput: React.FC<QuantityInputProps> = ({ quantity, onQuantityChange, maxLimit, minLimit = 0, smallSize = false }) => {

    const handleIncrement = () => {
        const newQuantity = quantity + 1;
        if (maxLimit === undefined || newQuantity <= maxLimit) {
            onQuantityChange(newQuantity);
        }
    };

    const handleDecrement = () => {
        const newQuantity = quantity - 1;
        if (newQuantity >= minLimit) {
            onQuantityChange(newQuantity);
        }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newQuantity = parseInt(e.target.value, 10) || 0;
        onQuantityChange(newQuantity);
    };

    const isIncrementDisabled = maxLimit !== undefined && quantity >= maxLimit;
    const isDecrementDisabled = quantity <= minLimit;

    return (
        <div className='d-flex align-items-center'>
            {
                smallSize ?
                    <>
                        <button
                            className={`d-flex justify-content-center align-items-center quantity-btn ${isDecrementDisabled ? 'quantity-btn--disabled' : ''} minus-btn`}
                            style={{ width: "1vw", height: "2vh" }}
                            onClick={handleDecrement}
                            disabled={isDecrementDisabled}
                        >
                            <span style={{ fontSize: "0.5rem" }}>
                                &mdash;
                            </span>

                        </button>
                        <span className="mx-1">{quantity}</span>
                        <button
                            className={`d-flex justify-content-center align-items-center quantity-btn ${isIncrementDisabled ? 'quantity-btn--disabled' : ''} plus-btn`}
                            style={{ width: "1vw", height: "2vh" }}
                            onClick={handleIncrement}
                            disabled={isIncrementDisabled}
                        >
                            <span style={{ fontSize: "0.5rem" }}>&#xff0b;</span>
                        </button>
                    </> :
                    <>
                        <button
                            className={`quantity-btn ${isDecrementDisabled ? 'quantity-btn--disabled' : ''} minus-btn`}
                            onClick={handleDecrement}
                            disabled={isDecrementDisabled}
                        >
                            <span>
                                &mdash;
                            </span>

                        </button>
                        <span className="mx-2">{quantity}</span>
                        <button
                            className={`quantity-btn ${isIncrementDisabled ? 'quantity-btn--disabled' : ''} plus-btn`}
                            onClick={handleIncrement}
                            disabled={isIncrementDisabled}
                        >
                            <span>&#xff0b;</span>
                        </button>
                    </>
            }

        </div>
    );
};

export default QtyInput;
