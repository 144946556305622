import i18n from "i18next";
import { initReactI18next } from "react-i18next";

//translation files
import loginEnTranslations from "../../locales/en/login.json";
import registerEnTranslations from "../../locales/en/register.json";
import loginFrTranslations from "../../locales/fr/login.json";
import registerFrTranslations from "../../locales/fr/register.json";
import homeEnTranslations from "../../locales/en/home.json";
import homeFrTranslations from "../../locales/fr/home.json";
import operationFrTranslations from "../../locales/fr/operations.json";
import operationEnTranslations from "../../locales/en/operations.json";
import transactionFR from '../../locales/fr/transactions.json';
import transactionEn from '../../locales/en/transactions.json';
import invoiceFr from  '../../locales/fr/invoices.json';
import invoiceEn from '../../locales/en/invoices.json';
import wireFr from '../../locales/fr/wires.json';
import wireEn from '../../locales/en/wires.json';
import banckAccountFR from '../../locales/fr/banckAccount.json';
import banckAccountEn from '../../locales/en/banckAccount.json';
import paylinkFr from '../../locales/fr/paylink.json';
import paylinkEn from '../../locales/en/paylink.json';
import qrCodeEn from '../../locales/en/QrCode.json';
import qrCodeFr from '../../locales/fr/QrCode.json';
import developerFr from "../../locales/fr/developer.json";
import developerEn from "../../locales/en/developer.json" 

// configure i18n and initialize translation
i18n.use(initReactI18next).init({
    resources: {
        //[LOCALES.ENGLISH]
        en: {
            login: loginEnTranslations,
            register: registerEnTranslations,
            Home:homeEnTranslations,
            Operations:operationEnTranslations,
            transaction:transactionEn,
            invoice:invoiceEn,
            wire:wireEn,
            banckAccount:banckAccountEn,
            paylink:paylinkEn,
            qrcode:qrCodeEn,
            developer:developerEn
            
        },
        fr: {
            login: loginFrTranslations,
            register: registerFrTranslations,
            Home:homeFrTranslations,
            Operations:operationFrTranslations,
            transaction:transactionFR,
            invoice:invoiceFr,
            wire:wireFr,
            banckAccount:banckAccountFR,
            paylink:paylinkFr,
            qrcode:qrCodeFr,
            developer:developerFr

        }
    },
    lng: "en",
    fallbackLng: "en",
    interpolation: {
        escapeValue: false
    }
});

export default i18n;