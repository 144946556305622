import { basePanoramix as axios } from './base'

/** Account */
export const getAccountDetails = async () =>
  await axios.get("account/user_profile");
export const completeSubmission = async (user) => {
  const config = {
    headers: { Authorization: `Token ${user.temporary_token}` },
  };

  const formData = new FormData();
  if (user.legal_status) formData.append("legal_status", user.legal_status);
  if (user.company_address)
    formData.append("company_address", user.company_address);
  if (user.fiscal_number)
    formData.append("company_mat_fisc", user.fiscal_number);
  if (user.website) formData.append("company_website", user.website);
  if (user.company_name) formData.append("company_name", user.company_name);

  if (user.activity) formData.append("company_activities", user.activity);
  if (user.cin_recto) formData.append("cin_img_recto", user.cin_recto);
  if (user.cin_verso) formData.append("cin_img_verso", user.cin_verso);
  if (user.business_tax) formData.append("patente_img", user.business_tax);
  if (user.business_number) formData.append("business_number", user.business_number);

  return await axios.patch("account/complete_submission", formData, config);
};

export const getAccessControlData = async () =>
  await axios.get("account/user_acl");

export const getMerchantsBelongTo = async () =>
  await axios.get("account/merchant_belongs")

/** Paylinks */

/* export const createPaylink = async (paylink,token) => {
    const config = {
        headers: { Authorization: `Token ${token}` }
    };
    return await axios.post('d}/detailspanoramix/paylinks/create/',config);
} */
export const createPaylink = async (paylink) => {
  const isoTimestamp = paylink.expiration_date;
  const date = new Date(isoTimestamp);
  //const formattedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`;

  const paylink_payload = {
    firstname: paylink.firstname,
    lastname: paylink.lastname,
    phone: paylink.phone_number,
    email: paylink.email,
    description: paylink.description,
    amount: paylink.amount,
    sent_using_email: paylink.sentByEmail,
    sent_using_sms: paylink.sentBySms,
    expiry_date: paylink.expiration_date,
  };
  return await axios.post("paylinks/create", paylink_payload);
};
export const createPaylinkCheckout = async (paylink) => {

  return await axios.post("paylinks/paylinks_checkout/create", paylink);
};

export const editPaylinkCheckout = async (paylink_id, paylink_details) => await axios.put(
  `paylinks/paylinks_checkout/${paylink_id}/edit`,
  paylink_details
)

export const getPaylinkDetails = async (paylink_id) =>
  await axios.get(`paylinks/${paylink_id}/details`);
export const getPaylinkDetailsByHash = async (paylink_hash) =>
  await axios.get(`paylinks/${paylink_hash}/checkout`);
export const resendPaylink = async (paylink_id) =>
  await axios.post(`paylinks/${paylink_id}/resend`);
export const reactivatePaylink = async (paylink_id) =>
  await axios.get(`paylinks/${paylink_id}/reactive`);
export const cancelPaylink = async (paylink_id) =>
  await axios.get(`paylinks/${paylink_id}/cancel`);

export const getAllPaylinks = async (obj, page = 1, limit = 10) => {
  if (obj.status === "PAID") {
    obj.status = true
  } else if (obj.status === "NOT_PAID") {
    obj.status = false
  }
  return await axios.get(
    `paylinks?query=${obj.query}&date_from=${obj.startDate}&date_to=${obj.endDate}&amount=${obj.amount}&amount_max=${obj.amount_max}&amount_min=${obj.amount_min}&paid=${obj.status}&page=${page}&limit=${limit}`
  );
};

export const getAllPaylinkAdvanced = async (obj, page = 1, limit = 10) => {
  return await axios.get(
    `paylinks/paylinks_checkout?query=${obj.query}&date_from=${obj.startDate}&date_to=${obj.endDate}&amount=${obj.amount}&amount_max=${obj.amount_max}&amount_min=${obj.amount_min}&type=${obj.status}&page=${page}&limit=${limit}`
  );
};

export const getPaylinkAdvancedDetails = async (paylink) =>
  await axios.get(`paylinks/paylinks_checkout/${paylink}/details`);

export const getPaylinkAdvancedOrders = async (paylink) =>
  await axios.get(`paylinks/paylinks_checkout/${paylink}/orders`);

export const bulkPaylinkAction = async (action_type, paylinks_id) => {
  const bulk_paylink_payload = {
    paylinks_id: paylinks_id,
    action: action_type, //"REACTIVE || CANCEL || SEND"
  };
  return await axios.post("paylinks/multiple", bulk_paylink_payload);
};

/** Wires */
export const getAllBankAccounts = async (obj, page = 1, limit = 10) => {
  return await axios.get(
    `bank_accounts?query=${obj.query}&date_from=${obj.startDate}&date_to=${obj.endDate}&bank=${obj.bank}&page=${page}&limit=${limit}`
  );
};

export const getBankAccountDetails = async (bank_account) =>
  await axios.get(`bank_accounts/${bank_account}/details`);
export const createBankAccount = async (bank_account) => {
  const bank_account_payload = {
    label: bank_account.label,
    bic: bank_account.bic,
    rib: bank_account.rib,
    phone: bank_account.phone_number,
  };

  return await axios.post(
    "bank_accounts/create",
    bank_account_payload
  );
};
export const deactivateBankAccount = async (bank_account_id) =>
  await axios.delete(`bank_accounts/${bank_account_id}/disable`);

export const updateBankAccount = async (bank_account_id, bank_account) =>
  await axios.put(
    `bank_accounts/${bank_account_id}/update`,
    bank_account
  );

export const getAllWires = async (obj, page = 1, limit = 10) => {
  return await axios.get(
    `wires?query=${obj.query}&date_from=${obj.startDate}&date_to=${obj.endDate}&amount=${obj.amount}&amount_max=${obj.amount_max}&amount_min=${obj.amount_min}&status=${obj.status}&page=${page}&limit=${limit}`
  );
};
export const createWireTransfer = async (wire) => {
  const wire_payload = {
    amount: wire.amount,
    bank_account: wire.bank_account,
  };

  return await axios.post("wires/create", wire_payload);
};
export const cancelWire = async (wire) =>
  await axios.put(`wires/${wire}/cancel`);

export const uploadWireDocument = async (wire_id, uploadedFile) => {
  const formData = new FormData();

  if (uploadedFile) formData.append("uploaded_file", uploadedFile);

  return await axios.patch(`wires/${wire_id}/upload`, formData);
};

/** Transactions */
export const getAllOperations = async (obj, page = 1, limit = 10) => {
  return await axios.get(
    `transactions?query=${obj.query}&date_from=${obj.startDate}&date_to=${obj.endDate}&amount=${obj.amount}&amount_max=${obj.amount_max}&amount_min=${obj.amount_min}&status=${obj.status}&page=${page}&limit=${limit}`
  );
};

export const requestRefund = async (refund_details) => {
  const refund_payload = {
    partial_refund: refund_details.refund_option === "partial" ? true : false,
    amount: refund_details.refunded_amount,
  };
  return await axios.post(
    `payment/refund/${refund_details.operation_id}`,
    refund_payload
  );
};
export const getAllTransactions = async (obj, page = 1, limit = 10) => {
  return await axios.get(
    `cb_transactions?query=${obj.query}&date_from=${obj.startDate}&date_to=${obj.endDate}&amount=${obj.amount}&amount_max=${obj.amount_max}&amount_min=${obj.amount_min}&status=${obj.status}&page=${page}&limit=${limit}`
  );
};
export const verifyTransaction = async (id) =>
  await axios.get(`cb_transactions/${id}/check`);
export const searchTransaction = async (query) =>
  await axios.get(`cb_transactions/${query}/search`);
export const filterTransaction = async (transaction_payload) =>
  await axios.post("cb_transactions/filter", transaction_payload);
export const fixPayment = async (operation_id) =>
  await axios.post("payment/fix", { transaction_id: operation_id });

/** Invoices */
export const getAllFactures = async (query = "") => {
  if (query !== "") {
    return await axios.get(`invoices?query=${query}`);
  } else {
    return await axios.get("invoices");
  }
};
export const getInvoiceFilter = async (year) =>
  await axios.get(`invoices/${year}/filter`);
export const downloadInvoice = async (id) =>
  await axios.get(`invoices/${id}/generate`);
/** Roles */
export const getAllPermissions = async () =>
  await axios.get("permissions/list");
export const getAllRoles = async (id) =>
  await axios.get(`account/${id}/created_roles`);
export const createRole = async (role) =>
  await axios.post("account/roles/create", role);
export const deleteRole = async (id) =>
  await axios.delete(`account/roles/${id}/delete`);
export const editDataRole = async (role) =>
  await axios.put(`account/roles/${role.id}/edit`, role);
/**users */
export const getAllUsers = async (id) =>
  await axios.get(`account/${id}/sub_accounts`);
export const createUser = async (user) =>
  await axios.post(`/account/users/create`, user);
export const deleteUser = async (id) =>
  await axios.delete(`account/users/${id}/delete`);
export const updateUser = async (user) =>
  await axios.put(`account/users/edit`, user);

/** Dashboard */
export const getKPI = async (payload) =>
  await axios.post(`dashboard-stats`, payload);

/** QRcode */
export const createQRcodeTPE = async (qrcode) => {
  const qrcode_payload = {
    label: qrcode.label,
  };
  await axios.post("qrcodes/create", qrcode_payload);
};

export const createQOD = async (qrcode) => {
  const qrcode_payload = {
    qod_note: qrcode.label,
    qod_amount: qrcode.amount,
  };

  await axios.post("qrcodes/on_delivery/create", qrcode_payload);
};

export const deleteQRcode = async (qrcode) =>
  await axios.delete(`qrcodes/${qrcode}/delete`);
export const searchQrcodes = async (query) =>
  await axios.get(`qrcodes/${query}/search`);

export const getAllQRcodes = async (obj, page = 1, limit = 10) => {
  return await axios.get(
    `qrcodes?query=${obj.query}&date_from=${obj.startDate}&date_to=${obj.endDate}&amount=${obj.amount}&amount_max=${obj.amount_max}&amount_min=${obj.amount_min}&page=${page}&limit=${limit}`
  );
};

export const bulkQRcodeAction = async (action_type, qrcodes_id) => {
  const bulk_qrcode_payload = {
    qrcodes_id: qrcodes_id,
    action: action_type,
  };
  return await axios.post("qrcodes/multiple", bulk_qrcode_payload);
};

export const getAllDeliveryQRcodes = async (query = "") => {
  if (query !== "") {
    return await axios.get(`qods?query=${query}`);
  } else {
    return await axios.get("qods");
  }
};

export const filterQrcodes = async (qrcode_payload) =>
  await axios.post("qrcodes/filter", qrcode_payload);

export const getQRcodeDetails = async (qrcode) =>
  await axios.get(`qrcodes/${qrcode}/details`);

export const updateQRcodeAmount = async (qrcode_id, amount) =>
  await axios.put(`qrcodes/${qrcode_id}/edit`, { amount: amount });

export const getQRcodeByHash = async (qrcode_hash) =>
  await axios.get(`qrcodes/${qrcode_hash}/checkout`);

export const downloadQRcode = async (qrcode_id) =>
  await axios.get(`qrcodes/${qrcode_id}/download`, { responseType: 'arraybuffer' });

export const getDataUser = async () =>
  await axios.get("account/myinfos");

export const editPassword = async (data) =>
  await axios.post("account/edit_password", data);

export const editNotification = async (data) =>
  await axios.patch("account/edit_notifications", data);

export const configuration = {
  editPassword,
  editNotification,
};
export const cardRequest = async (data) =>
  await axios.post("account/request_ic", data);


export const editLogo = async (data) =>
  await axios.put("account/update_logo", data);

export const deleteLogo = async () => {
  return await axios.delete("account/update_logo");
};

export const editPhoto = async (data) =>
  await axios.put("account/photo", data);
export const updateStatusNotifAlert = async () =>
  await axios.put("notifications/account_verification/markAsSeen"
  );
export const createProduct = async (data) =>
  await axios.post("paylink-checkout/products/create", data);

export const editProduct = async (data) =>
  await axios.post(`paylink-checkout/products/${data.id}/edit`, data);

export const deleteProduct = async (product_Id) =>
  await axios.delete(`paylink-checkout/products/${product_Id}/delete`);

export const activateProduct = async (product_Id) =>
  await axios.put(
    `paylink-checkout/products/${product_Id}/activate`
  );

export const updateAimedRevenue = async (data) => {
  await axios.put("account/update_aim", { "aimed_revenue": data })
}

export const getAllProducts = async (obj) => {
  return await axios.get(
    `paylink-checkout/products?query=${obj.query}&date_from=${obj.startDate}&date_to=${obj.endDate}`

  )
};

export const getAllProductsByType = async (product_type) => {
  let type = "";
  if (product_type === "sell-products") type = "product"
  else if (product_type === "event-payment") type = "event"

  return await axios.get(
    `paylink-checkout/products?type=${type}`
  );
}
export const getAllDeliveryOptions = async () => {

  return await axios.get(
    `paylink-checkout/delivery-options`
  );
};

export const sendPaylink = async (paylink_id, sending_option) =>
  await axios.post(`paylinks/${paylink_id}/send`, { "sending_option": sending_option });


export const sendPaylinkAdvanced = async (paylink, sending_option) => {
  const { id: paylink_id, phone_number, email } = paylink;
  let send_to = "";

  if (sending_option == "email") send_to = email
  else send_to = phone_number

  return await axios.post(`paylinks/${paylink_id}/send`, { "sending_option": sending_option, "send_to": send_to });
}


export const regenerateToken = async () =>
  await axios.post(`account/regenerate-token`);

export const uploadDocOperation = async (id, data) =>
  await axios.patch("transactions/" + id + "/upload_doc", data);

export const getContractCGUV = async () =>
  await axios.get('account/contract/generate', { responseType: 'blob' })

export const getAllNotifications = async (obj, page = 1, limit = 10) =>
  await axios.get(`mynotifications?&page=${page}&limit=${limit}`)

export const deleteNotification = async (notification) => await axios.delete(`mynotifications/${notification}/delete`)

export const MarkNotificationAsSeen = async (notification) => await axios.put(`mynotifications/${notification}/markAsSeen`);