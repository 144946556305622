import React, { useState } from 'react'
import { Button } from 'reactstrap'
//import '../../../../src/scss/components/form/button.scss'
type style={
  width ?:"100%"
  height:"50"
}
type ButtonProps = {
  
  type: "submit" | "button"; 
  isActive?: boolean;
  label: string;
  size?: "sm" | "lg" | "block";
  onClick?: (event: any) => void;
  href?: string;
  //children?: React.ReactNode | undefined;
  withIcon?: boolean;
  icon?: JSX.Element;
  iconColor?: string;
  
}

const PButton: React.FC<ButtonProps> = (props: ButtonProps) => {

  const { type, label, size, onClick, href, isActive = true, withIcon = false, icon, iconColor } = props;

  const [isHovered, setIsHovered] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setIsClicked(!isClicked);
    if (onClick) {
      onClick(event);
    }
  };


  let className = "normal";

  if (isHovered) {
    className = "hovered";
  }
  if (isClicked) {
    className = "clicked";
  }

  return (
    <>
      {
        withIcon ?
        <Button
        className={`pbutton-${className}`}
        size={size}
        onClick={handleClick}
        href={href}
        disabled={false}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        outline={false}
        style={{
          background: "#213A7D",
          borderRadius: "4px",
          width: "100%"
        }}
      >
            {icon}
            <span> {label}</span>
          </Button>
          :
          <Button
            className={`pbutton-${className}`}
            size={size}
            onClick={handleClick}
            href={href}
            disabled={false}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            outline={false}
            style={{
              background: "#213A7D",
              borderRadius: "4px",
              width: "100%"
            }}
          >
            {label}
          </Button>
      }
    </>

  )
}

export default PButton