import React, { startTransition, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { Container, Row, Col, Button, Badge } from "reactstrap";
import logo from "../assets/images/logo_paymee.png";
import logoSmall from "../assets/images/image_4.png";
import balanceIcon from "../assets/images/balance_icon.svg";
import avatar from "../assets/images/avatar-default-icon.png";
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from "reactstrap";
import { Settings, Power } from "react-feather";
import ReactCountryFlag from "react-country-flag";
import backArrow from "../assets/images/back arrow.svg";
import "./Layout.css";
import { Toaster, toast } from "react-hot-toast";

import {
  getAccessControlData,
  getAccountDetails,
  getAllNotifications,
  getDataUser,
  getKPI,
} from "../services/api/panormix";
import SideDrawer from "../components/navigation/SideDrawer/SideDrawer";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserData } from "../redux/slices/userSlice";
import { fetchProfile } from "../redux/slices/profileSlice";
import {
  AbilityContext,
  AllowedCan,
  ShowHideCan,
  getAbility,
} from "../utils/permissions";
import { convertFormat_balance, formatDatetime } from "../utils/helper";
import { useTranslation } from "react-i18next";
import {
  assignedPermissions,
  updatePermissions,
} from "../redux/slices/permissionsSlice";
import { resetCompanyState } from "../redux/slices/companySlice";
import { resetStore } from "../redux/slices/reset";
import { resetAll } from "../redux/slices/resetStore";
import { HiOutlineViewList } from "react-icons/hi";
import MediaQuery from "react-responsive";
import { BsBank, BsFillBellFill } from "react-icons/bs";
import { BiBadge, BiSolidBadge } from "react-icons/bi";
import { LiaFileInvoiceSolid } from "react-icons/lia";
import { RiLockPasswordFill, RiRefund2Fill } from "react-icons/ri";
import {
  MdInsertLink,
  MdManageAccounts,
  MdOutlinePayment,
} from "react-icons/md";
import { IoLogInOutline } from "react-icons/io5";
import { AiOutlineLink } from "react-icons/ai";
import { HiQrCode } from "react-icons/hi2";
import {
  FaBell,
  FaDotCircle,
  FaRegDotCircle,
  FaUserFriends,
} from "react-icons/fa";
import { GoDotFill } from "react-icons/go";
export function Layout({ children }) {
  const dispatch = useDispatch();
  const dispatchAction = useDispatch();

  const { logout, isAuthenticated } = useAuth();
  //const { profile, setProfile } = useContext(ProfileContext);
  const userInfo = useSelector((state) => state.user);
  const profile = useSelector((state) => state.profile);
  //const { userInfo } = useContext(ProfileContext);

  const navigate = useNavigate();
  const { i18n } = useTranslation();

  const [language, setLanguage] = useState("en-US");

  const { t } = useTranslation("Home");
  const [notifications, setNotifications] = useState();
  const optionsListLanguages = [
    {
      value: "fr-FR",
      label: <span style={{ marginLeft: "10px" }}>FR</span>,
      flag: <ReactCountryFlag countryCode="fr" svg style={{ width: "29px" }} />,
    }, //fr
    {
      value: "en-US",
      label: <span style={{ marginLeft: "10px" }}>EN</span>,
      flag: (
        <ReactCountryFlag
          className="country-flag"
          countryCode="us"
          svg
          style={{ width: "29px" }}
        />
      ),
    }, //en
  ];

  const [langLabel, setLangLabel] = useState(optionsListLanguages[0]);

  const [permissions, setPermissions] = useState({});

  var adaptedPermissions = {};

  const [solde, setSolde] = useState("");

  const [weekSelected, setWeekSelected] = useState(0);
  const [monthSelected, setMonthSelected] = useState("");

  const chosenCompany = useSelector((state) => state.company.chosenCompany);
  const permissionsList = useSelector((state) => state.permissions);
  useEffect(() => {
    i18n.changeLanguage("fr");

    const payload = {
      success_rate: {
        enable_filter: false,
      },
      revenue_by_month: {
        enable_filter: false,
      },
      revenue_growth: {
        enable_filter: true,
        month: monthSelected,
        week: weekSelected,
      },
    };

    if (isAuthenticated) {
      getKPI(payload).then((result) => {
        setSolde(convertFormat_balance(result.data.balance));
      });

      getAccessControlData()
        .then((res) => {
          let perms = res.data.permissions;
          perms.forEach((permission) => {
            const { module, tag } = permission;

            if (!adaptedPermissions[module]) {
              adaptedPermissions[module] = [];
            }

            adaptedPermissions[module].push(tag);
          });
          setPermissions(adaptedPermissions);
          dispatch(updatePermissions(res.data.permissions));
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 400) {
              console.log("err.response.status :", err.response.status);
            } else if (err.response.status === 500 && isAuthenticated) {
              console.log("err.response.status :", err.response.status);
            }
          } else if (err.request && isAuthenticated) {
            toast.error("Network Error : no response from server");
          }
        });
    }
  }, [isAuthenticated, chosenCompany]);

  useEffect(() => {
    getDataUser()
      .then((res) => {
        dispatch(fetchUserData());
      })
      .catch((error) => {
        // Handle error
      });
  }, [dispatch]);

  useEffect(() => {
    getAccountDetails()
      .then((res) => {
        dispatch(fetchProfile());
      })
      .catch((error) => {
        console.log("error:", error);
      });

    getAllNotifications()
      .then((res) => {
        console.log("Notififf : ", res.data);
        setNotifications(res.data.notifications);
      })
      .catch((error) => {
        console.log("chosenCompany : ", localStorage.getItem("chosenCompany"));
        console.log("error:", error);
      });
  }, [dispatch]);

  const ability = getAbility(permissions);

  const handleLogout = () => {
    dispatch(resetStore());
    dispatch(resetAll());
    logout();
    dispatch(resetCompanyState());
    localStorage.clear();
    startTransition(() => {
      navigate("/login");
    });
  };
  const goToSettings = () => {
    startTransition(() => {
      navigate("/settings");
    });
  };
  const handleLangUpdate = (newLang) => {
    newLang == "fr"
      ? setLangLabel(optionsListLanguages[0])
      : setLangLabel(optionsListLanguages[1]);
    i18n.changeLanguage(newLang);
    setLanguage(newLang);
  };

  return (
    <>
      <AbilityContext.Provider value={ability}>
        <Toaster position="top-right" reverseOrder={true} />
        <div className="main-wrapper">
          <Row style={{ marginTop: "4rem" }}>
            {" "}
            <header
              style={{
                position: "fixed",
                width: "100%",
                top: "0",
                zIndex: "1000",
              }}
            >
              <Row style={{ marginRight: "0", height: "15%" }}>
                <Col
                  xxl={2}
                  xl={2}
                  lg={3}
                  md={1}
                  sm={1}
                  xs={2}
                  onClick={() => {
                    startTransition(() => {
                      if (
                        (profile && profile.role === "administrateur") ||
                        (profile && profile.role === "administrator")
                      ) {
                        navigate("/");
                      } else {
                        navigate("/");
                      }
                    });
                  }}
                >
                  <MediaQuery minWidth={992}>
                    {" "}
                    <img
                      src={logo}
                      className="img-fluid rounded-start logo"
                      alt="..."
                      style={{
                        width: "200px",
                        marginLeft: "20px",
                        marginTop: "10px",
                        height: "50px",
                        zoom: "85%",
                      }}
                    />
                  </MediaQuery>
                  <MediaQuery maxWidth={991}>
                    <img
                      src={logoSmall}
                      className="img-fluid rounded-start logo"
                      alt="..."
                      style={{
                        width: "40px",
                        marginLeft: "20px",
                        marginTop: "10px",
                        height: "40px",
                        zoom: "100%",
                      }}
                    />
                  </MediaQuery>
                </Col>
                <Col xxl={8} xl={7} lg={6} md={6} sm={7} xs={7}>
                  <div className="Balance">
                    <ShowHideCan I="view_balance" a="SUBACCOUNTS">
                      <div id="container">
                        <Row>
                          <div style={{ marginTop: "10px", width: "3rem" }}>
                            <img src={balanceIcon} />
                          </div>
                          <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                            <Row>
                              <Col
                                id="soldeAccount"
                                xxl={1}
                                xl={1}
                                lg={1}
                                md={1}
                                style={{
                                  marginTop: "10px",
                                }}
                              >
                                <span
                                  style={{
                                    fontFamily: "Inter",
                                    fontStyle: "normal",
                                    fontWeight: "500",
                                    fontSize: "16px",
                                    lineHeight: "19px",
                                    display: "flex",
                                    alignItems: "center",

                                    color: "#A3ACB9",
                                  }}
                                >
                                  {t("menuTop")}
                                </span>
                              </Col>
                            </Row>

                            <Row>
                              <Col
                                id="soldeAccount"
                                // xl={3}
                                style={{
                                  marginTop: "-3px",
                                  marginBottom: "0.5%",
                                }}
                              >
                                <span
                                id="textMinSize"
                                  style={{
                                    color: "#034DA3",
                                    fontFamily: "Inter",
                                    fontWeight: "600",
                                  }}
                                >
                                  {solde} {profile && profile.currency_iso}
                                </span>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    </ShowHideCan>
                  </div>
                </Col>
                <Col xxl={2} xl={3} lg={3} md={5} sm={4} xs={3}>
                  <Row>
                    <Col xxl={7} xl={7} lg={7} md={7} sm={8} xs={4}></Col>

                    <Col
                      xxl={3}
                      xl={3}
                      lg={3}
                      md={3}
                      sm={8}
                      xs={4}
                      style={{ marginTop: "5px" }}
                    >
                      <div
                        className="dropdown-notifications"
                        style={{
                          // borderRight: "2px inset #709DD3",
                          marginLeft: "35%",
                        }}
                      >
                        <UncontrolledDropdown
                          tag="li"
                          className="dropdown-notification nav-item "
                          style={{
                            margin: "10px",
                            listStyleType: "none",
                          }}
                        >
                          <DropdownToggle
                            tag="a"
                            className="nav-link"
                            href="/"
                            onClick={(e) => e.preventDefault()}
                          >
                            <Row style={{ flexWrap: "revert" }}>
                              <Col xs="1">
                                <BsFillBellFill size={25} color="#034DA3" />
                              </Col>
                              <Col xs="1" style={{ marginLeft: "-9px" }}>
                                {notifications &&
                                  notifications.filter((notif) => !notif.seen)
                                    .length > 0 && (
                                    <Badge
                                      pill
                                      color="danger"
                                      className="badge-up"
                                      style={{
                                        fontSize: "0.7rem",
                                        padding: "0.2rem 0.4rem",
                                      }}
                                    >
                                      {
                                        notifications.filter(
                                          (notif) => !notif.seen
                                        ).length
                                      }
                                    </Badge>
                                  )}
                              </Col>
                            </Row>
                          </DropdownToggle>
                          <DropdownMenu
                            tag="ul"
                            right
                            className="dropdown-menu-media mt-0"
                            style={{
                              width: "300px",
                            }}
                          >
                            <Row class="d-flex justify-content-between mx-2">
                              <Col className="d-flex align-items-center justify-content-start mx-2">
                                <span
                                  style={{
                                    color: "#125FB9",
                                    fontSize: "20px",
                                    fontWeight: "700",
                                    cursor: "default",
                                  }}
                                >
                                  Notifications
                                </span>
                              </Col>
                              <Col className="d-flex align-items-center justify-content-end mx-2">
                                <Settings
                                  size={20}
                                  color="#125FB9"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => navigate("/notifications")}
                                  className="icon-rotate"
                                />
                              </Col>
                            </Row>
                            <hr
                              className="mx-2"
                              style={{
                                backgroundColor: "#C2C7CF",
                                marginTop: "0.5rem",
                                marginBottom: "10px",
                              }}
                            />
                            {notifications &&
                              notifications.length > 0 &&
                              notifications.slice(0, 4).map((notif, index) => {
                                return (
                                  <>
                                    <div
                                      className="notification-item"
                                      style={{
                                        height: "50px",
                                        cursor: "pointer",
                                        padding: "5px",
                                      }}
                                    >
                                      <Row
                                        className="mx-1"
                                        style={{
                                          backgroundColor: "#f2f2f2",
                                          height: "50px",
                                          borderRadius: "10px 10px",
                                        }}
                                      >
                                        <Col
                                          sm={3}
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <div
                                            style={{
                                              backgroundColor: "#63A4FF",
                                              borderRadius: "50%",
                                              width: "40px",
                                              height: "40px",
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "center",
                                            }}
                                          >
                                            {notif.tag === "wire_transfer" ? (
                                              <BsBank
                                                color="white"
                                                className="mx-2"
                                                size={20}
                                              />
                                            ) : notif.tag ===
                                              "invoice_generated" ? (
                                              <LiaFileInvoiceSolid
                                                color="white"
                                                className="mx-2"
                                                size={20}
                                              />
                                            ) : notif.tag ===
                                              "account_security" ? (
                                              <RiLockPasswordFill
                                                color="white"
                                                className="mx-2"
                                                size={20}
                                              />
                                            ) : notif.tag ===
                                              "account_activation" ? (
                                              <MdManageAccounts
                                                color="white"
                                                className="mx-2"
                                                size={20}
                                              />
                                            ) : notif.tag ===
                                              "refund_request_validated" ? (
                                              <RiRefund2Fill
                                                color="white"
                                                className="mx-2"
                                                size={20}
                                              />
                                            ) : notif.tag ===
                                              "request_international_payment" ? (
                                              <MdOutlinePayment
                                                color="white"
                                                className="mx-2"
                                                size={20}
                                              />
                                            ) : notif.tag ===
                                              "subaccount_first_login" ? (
                                              <IoLogInOutline
                                                color="white"
                                                className="mx-2"
                                                size={20}
                                              />
                                            ) : notif.tag ===
                                              "subaccount_paylink_actions" ? (
                                              <MdInsertLink
                                                color="white"
                                                className="mx-2"
                                                size={20}
                                              />
                                            ) : notif.tag ===
                                              "subaccount_wire_actions" ? (
                                              <BsBank
                                                color="white"
                                                className="mx-2"
                                                size={20}
                                              />
                                            ) : notif.tag ===
                                              "subaccount_qrcode_actions" ? (
                                              <HiQrCode
                                                color="white"
                                                className="mx-2"
                                                size={20}
                                              />
                                            ) : notif.tag ===
                                              "subaccount_paylink_advanced_actions" ? (
                                              <AiOutlineLink
                                                color="white"
                                                className="mx-2"
                                                size={20}
                                              />
                                            ) : notif.tag ===
                                              "subaccount_roles_actions" ? (
                                              <>
                                                <FaUserFriends
                                                  color="white"
                                                  className="mx-2"
                                                  size={20}
                                                />
                                              </>
                                            ) : notif.tag ===
                                              "subaccount_users_actions" ? (
                                              <>
                                                <FaUserFriends
                                                  color="white"
                                                  className="mx-2"
                                                  size={20}
                                                />
                                              </>
                                            ) : (
                                              <></>
                                            )}
                                          </div>
                                        </Col>
                                        <Col
                                          sm={7}
                                          style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                          }}
                                        >
                                          <span
                                            className="notif-title"
                                            style={{
                                              fontWeight: "bold",
                                              fontSize: "12px",
                                              color: "#939393",
                                            }}
                                          >
                                            {notif.title}
                                          </span>
                                          <span
                                            className="notif-date"
                                            style={{
                                              color: "gray",
                                              fontSize: "10px",
                                            }}
                                          >
                                            {formatDatetime(notif.date_added)}
                                          </span>
                                        </Col>
                                        <Col
                                          sm={2}
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                          }}
                                        >
                                          {!notif.seen && (
                                            <GoDotFill
                                              color="#63A4FF"
                                              size="20"
                                            />
                                          )}
                                        </Col>
                                      </Row>
                                    </div>
                                    {index <
                                      notifications.slice(0, 4).length - 1 && (
                                      <hr
                                        style={{
                                          marginBottom: "0px",
                                          marginTop: "10px",
                                        }}
                                        className="mx-2"
                                      />
                                    )}
                                  </>
                                );
                              })}
                            <hr
                              style={{
                                marginTop: "20px",
                                marginBottom: "0.5rem",
                              }}
                              className="mx-2"
                            />
                            <Button
                              className="mx-2 notif-all"
                              style={{
                                //backgroundColor: "#63A4FF",
                                background: "none",
                                border: "none",
                                width: "95%",
                                color: "#125FB9",
                              }}
                              onClick={() =>
                                startTransition(() => {
                                  navigate("/notifications");
                                })
                              }
                            >
                              Lire les autres notifications
                            </Button>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </div>
                    </Col>
                    <Col xxl={1} xl={2} lg={2} md={2} sm={2} xs={2}>
                      <div
                        className="dropdown-logged-user"
                        style={{ cursor: "pointer", marginTop: "10px" }}
                      >
                        <UncontrolledDropdown
                          tag="li"
                          className="dropdown-user nav-item"
                          style={{
                            // marginTop: "12px",
                            listStyleType: "none",
                          }}
                        >
                          <DropdownToggle
                            href="/"
                            tag="a"
                            className="nav-link dropdown-user-link"
                            onClick={(e) => e.preventDefault()}
                          >
                            {userInfo && userInfo.photo != null ? (
                              <>
                                <img
                                  src={userInfo.photo}
                                  alt="..."
                                  style={{
                                    height: "40px",
                                    borderRadius: "5px",
                                  }}
                                />
                              </>
                            ) : (
                              <>
                                <img
                                  src={avatar}
                                  alt="..."
                                  style={{
                                    height: "40px",
                                    borderRadius: "5px",
                                  }}
                                />
                              </>
                            )}
                          </DropdownToggle>
                          <DropdownMenu end>
                            <div
                              style={{
                                paddingLeft: "1rem",
                                paddingTop: "1rem",
                              }}
                            >
                              <div>
                                <span
                                  style={{
                                    fontFamily: "Inter",
                                    fontStyle: "normal",
                                    fontWeight: "500",
                                    fontSize: "16px",
                                    lineHeight: "19px",
                                    display: "flex",
                                    alignItems: "center",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  {userInfo && userInfo.user_profile.first_name}
                                </span>
                                <span
                                  style={{
                                    fontFamily: "Inter",
                                    fontStyle: "normal",
                                    fontWeight: "500",
                                    fontSize: "14px",
                                    lineHeight: "19px",
                                    display: "flex",
                                    alignItems: "center",
                                    whiteSpace: "nowrap",
                                    color: "#213A7D",
                                  }}
                                >
                                  {profile &&
                                    profile.role.charAt(0).toUpperCase() +
                                      profile.role.slice(1)}
                                </span>
                              </div>
                            </div>
                            <div
                              style={{
                                paddingLeft: "1rem",
                                paddingRight: "1rem",
                              }}
                            >
                              <div className="dropdown-divider"></div>
                            </div>
                            <div>
                              <DropdownItem onClick={goToSettings}>
                                <Settings
                                  size={14}
                                  style={{ marginRight: "5px" }}
                                />
                                <span className="align-middle">Paramétre</span>
                              </DropdownItem>
                              <DropdownItem onClick={handleLogout}>
                                <Power
                                  size={14}
                                  style={{ marginRight: "5px" }}
                                />
                                <span className="align-middle">
                                  Déconnexion
                                </span>
                              </DropdownItem>
                            </div>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </header>
          </Row>
          <Row>
            <main>
              <Row id="topMenu" style={{ marginRight: "5%" }}>
                <Col
                  id="sideDrawer"
                  style={{ marginTop: "-1px" }}
                  xxl={2}
                  xl={2}
                  lg={3}
                  md={1}
                  sm={1}
                  xs={2}
                >
                  <SideDrawer profile={profile} />
                </Col>
                <Col
                  xxl={10}
                  xl={10}
                  lg={9}
                  md={11}
                  sm={11}
                  xs={10}
                  style={{ height: "60.5px" }}
                  // borderTop: "1px inset #A3ACB9"
                >
                  <MediaQuery minWidth={1000}>
                    <body
                      id="bodyMain"
                      style={{
                        position: "fixed",
                        width: "84%",
                        height: "100vh",
                      }}
                    >
                      <div
                        className="dashboard-content"
                        id="content-page"
                      >
                        {children}
                      </div>
                    </body>
                  </MediaQuery>
                  <MediaQuery maxWidth={999}>
                    <body
                      id="bodyMain"
                      style={{ position: "fixed", width: "100%" }}
                    >
                      <div
                        className="dashboard-content"
                        id="content-page-lg"
                      >
                        {children}
                      </div>
                    </body>
                  </MediaQuery>
                </Col>
              </Row>
            </main>
          </Row>

          <footer></footer>
        </div>
      </AbilityContext.Provider>
    </>
  );
}
