import React, { useEffect } from "react";
import { confirmPayment } from "../../services/api/gateway";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import { DotLoader } from "react-spinners";
import { Row } from "reactstrap";
import { useDispatch } from "react-redux";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { updataeActualStepProg } from "../../redux/slices/paymentCheckoutSlice";


const RedirectConfirmPayment = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

  //const { orderId } = useParams();
  const orderId = localStorage.getItem("payment_order");
  const signature = localStorage.getItem("sign");

  useEffect(() => {

    confirmPayment(orderId, signature)
      .then((res) => {
        localStorage.removeItem("payment_order");
        dispatch(updataeActualStepProg({ step: 2, prog: 100 }))
        navigate("/gateway/success");
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 400) {

            localStorage.removeItem("payment_order");
            navigate("/gateway/failure");
          } else if (err.response.status === 500) {
            toast.error("Request Error: Error occured while trying to login");
          }
        } else if (err.request) {
          toast.error("Network Error : no response from server");
        }
      });

  }, []);

  return (
    <>
      <Row className="d-flex justify-content-center align-items-center" style={{ height: "800px" }}>
        <DotLoader
          color="rgb(112, 157, 211)"
          loading
          size={100}
          speedMultiplier={2}
        />
      </Row>
    </>
  );
};

export default RedirectConfirmPayment;
