import React, { startTransition, useEffect, useState } from 'react'
import PButton from "../../components/form/button/Button";
import success from '../../assets/images/success.gif'
import { updataeActualStepProg } from '../../redux/slices/paymentCheckoutSlice';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

const PaymentSuccess = () => {

  const dispatch = useDispatch();

  useEffect(() => {
    console.log("Dispatching action...");
    window.parent.postMessage({ type: 'PAYMENT_SUCCESS' }, '*');
    dispatch(updataeActualStepProg({ step: 3, prog: 100 }));
  }, [dispatch]);

  return (
    <>
      <div className='shadow-sm p-3  bg-white rounded mt-2' style={{ marginLeft: "25%", marginRight: "25%" }}>
        <div className="card-body">
          <div className='text-center' style={{ color: "#258750", fontSize: "28px", fontFamily: "Inter", fontWeight: "700" }}>Paiement réussi !</div>
          <div className='text-center d-flex justify-content-center'>
            <img src={success}></img>
          </div>
          <div className='mt-2 ' >
            <PButton size='lg' type="submit"
              label="Continuer"
              onClick={async () => {
                let redirectUrl = await localStorage.getItem('website');
                if (redirectUrl) {
                  if (!/^https?:\/\//i.test(redirectUrl)) {
                    redirectUrl = 'http://' + redirectUrl;
                  }

                  if (window.top) {
                    window.top.location.href = redirectUrl;
                  } else {
                    console.error("Unable to acces");
                  }
                } else {
                  console.error("URL is empty");
                }
              }}></PButton>
          </div>
        </div>
      </div>
    </>
  )
}

export default PaymentSuccess