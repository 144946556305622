import React from 'react';

import { BsArrowUpRight, BsBox2 } from "react-icons/bs";
import { GiTicket } from "react-icons/gi";
import { FiFilter, FiGift, FiSearch } from "react-icons/fi";

export interface CheckoutDropDownOption {
    value: string;
    label: string;
    description: string;
    icon: JSX.Element;
}

export const CheckoutDropDownOptions: CheckoutDropDownOption[] = [
    {
        value: "sell-products",
        label: "Sell an item",
        description: "Best for selling an item online using a single shared link.",
        icon: <BsBox2 />,
    },
    {
        value: "event-payment",
        label: "Sell classes or tickets",
        description: "Best for hosting online or in-person events or classes.",
        icon: <GiTicket />,
    },
    {
        value: "accept-donation",
        label: "Accept a donation",
        description: "Best for letting people decide the amount they want to give.",
        icon: <FiGift />,
    },
];