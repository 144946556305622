import { createSlice } from '@reduxjs/toolkit';

const companySlice = createSlice({
    name: 'company',
    initialState: {
        companies: [],
        chosenCompany: null,
    },
    reducers: {
        setCompanies(state, action) {
            state.companies = action.payload;

            if (state.companies.length > 0) {
                state.chosenCompany = state.companies[0].manager_id;
                localStorage.setItem("chosenCompany", state.companies[0].manager_id);
            }
        },
        setChosenCompany(state, action) {
            state.chosenCompany = action.payload;
            localStorage.setItem("chosenCompany", action.payload);
        },

        resetCompanyState(state) {
            state.companies = [];
            state.chosenCompany = null;
            localStorage.removeItem("chosenCompany");
        },
    },
});

export const { setCompanies, setChosenCompany, resetCompanyState } = companySlice.actions;
export default companySlice.reducer;