import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getDataUser } from '../../services/api/panormix';

export const fetchUserData = createAsyncThunk('user/fetchUserData', async () => {
    const response = await getDataUser();
    return response.data;
});

const userSlice = createSlice({
    name: 'user',
    initialState: null,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchUserData.fulfilled, (state, action) => {
                return action.payload;
            });
    },
});

export default userSlice.reducer;