import React, { startTransition, useContext, useEffect, useState } from 'react'
import { FaAngleDown, FaAngleUp, FaFileInvoice, FaLaptopCode, FaLink, FaQrcode, FaRegSun, FaTruck } from 'react-icons/fa'
import { FiHome } from 'react-icons/fi'
import { Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Nav, NavItem, NavLink, Row, UncontrolledDropdown } from 'reactstrap'
import NavLinkWithCollapse from '../NavigationItem/NavLinkWithCollapse'
import { useLocation, useNavigate } from 'react-router-dom'
import { RiRepeatFill } from 'react-icons/ri'
import { BsBank } from 'react-icons/bs'
import { AiOutlineLink } from 'react-icons/ai'
import { HiQrCode } from "react-icons/hi2"
import { HiOutlineComputerDesktop } from 'react-icons/hi2'
import { useTranslation } from "react-i18next";
import { AnyAllowedCan, AtElseCan, ShowHideCan } from '../../../utils/permissions'
import { IoSpeedometerOutline } from 'react-icons/io5'
import SideDrawerSkeleton from './SideDrawerSkeleton'
import { useDispatch, useSelector } from "react-redux";

import storeIcon from '../../../assets/images/Store icon.svg';
import { setChosenCompany, setCompanies } from '../../../redux/slices/companySlice'
import "./SideDrawer.css";
import { getAccessControlData, getMerchantsBelongTo } from '../../../services/api/panormix';
import { Toaster, toast } from "react-hot-toast";
import { updatePermissions } from '../../../redux/slices/permissionsSlice'
import { LiaFileInvoiceSolid, LiaMoneyCheckAltSolid } from 'react-icons/lia'
import { MdInsertLink } from 'react-icons/md'
import { Padding } from '@mui/icons-material'

type Props = {
    profile: any
}

const SideDrawer = (props: Props) => {
    const { profile } = props;

    const location = useLocation();
    const [activeLink, setActiveLink] = useState(location.pathname);
    const [isAngleDown, setIsAngleDown] = useState(true);
    const [isCompanyListOpen, setIsCompanyListOpen] = useState(false);
    const [permissionsLoaded, setPermissionsLoaded] = useState(true);

    const { t } = useTranslation("Home");
    const { i18n } = useTranslation();
    const navigate = useNavigate();

    const permissions = useSelector((state: any) => state.permissions);

    const companies = useSelector((state: any) => state.company.companies);
    const chosenCompany = useSelector((state: any) => state.company.chosenCompany);
    const dispatch = useDispatch();
    const company = companies.find((company: any) => company.manager_id === chosenCompany)

    useEffect(() => {
        setActiveLink(location.pathname);

    }, [location.pathname, t]);

    useEffect(() => {
        setTimeout(() => {
            setPermissionsLoaded(true);
        }, 1000);
    }, []);

    const handleLinkClick = (path: string) => {
        // setActiveLink(path);
        startTransition(() => {
            navigate(path)
        })
    };

    const toggleListCompanies = () => {
        setIsAngleDown(prevState => !prevState);
        setIsCompanyListOpen(prevState => !prevState);
    };

    const handleCompanyChange = (managerId: any) => {
        toggleListCompanies();
        dispatch(setChosenCompany(managerId));

        getAccessControlData()
            .then((res) => {
                console.log("here inside sideDra")
                dispatch(updatePermissions(res.data.permissions));
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status === 400) {
                        console.log(err.response)
                    } else if (err.response.status === 500) {
                        toast.error("Request Error: Error occured while trying to login");
                    }
                } else if (err.request) {
                    toast.error("Network Error : no response from server");
                }
            });
    };

    return (
        <aside
            className="position-fixed left-sidebar"
            style={{
                padding: "32px 20px",
                height: "100rem",
                background: "#E9EFF8",
                width: "100%"

            }}
        >
            {
                !permissionsLoaded ?
                    <SideDrawerSkeleton /> :
                    <>
                        {companies && companies.length > 0 &&
                            <>
                                <Row className='my-2'>
                                    <Col>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <div className='store-icon-container' style={{
                                                width: "30px",
                                                height: "30px",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                padding: "6px",
                                                borderRadius: "50px",
                                                background: "#FFF",
                                                boxShadow: "1px 1px 1px 1px rgba(0, 0, 0, 0.12), 0px 2px 5px 0px rgba(60, 66, 87, 0.08)"
                                            }}>
                                                <img
                                                    src={company.company_details.logo ? `https://api.staging.paymee.app${company.company_details.logo}` : storeIcon}
                                                    className="img-fluid rounded-start"
                                                    alt="..."
                                                />
                                            </div>
                                            <span style={{
                                                color: "var(--Shades-Grey-900, #1A1F36)",
                                                fontSize: "16px",
                                                fontStyle: "normal",
                                                fontWeight: 500,
                                                lineHeight: "normal",
                                                paddingLeft: "10px"
                                            }}>
                                                {company && company.company_details && company.company_details.company}
                                            </span>
                                        </div>
                                    </Col>
                                    {
                                        companies.length > 1 &&
                                        <Col className="d-flex justify-content-end align-items-center" onClick={toggleListCompanies} style={{ cursor: 'pointer' }}>
                                            {isAngleDown ? <FaAngleDown /> : <FaAngleUp />}
                                        </Col>
                                    }

                                </Row>
                                {isCompanyListOpen && (
                                    <>
                                        {companies.map((company: any) => (
                                            <Row key={company.id} className='my-2 mx-2 company-item' style={{ cursor: 'pointer' }} onClick={() => handleCompanyChange(company.manager_id)}>
                                                <Col>
                                                    <img
                                                        src={company.company_details.logo ? `https://api.staging.paymee.app${company.company_details.logo}` : storeIcon}
                                                        className="img-fluid rounded-start"
                                                        alt="Company Logo"
                                                        style={{ width: "25px" }}
                                                    />
                                                </Col>
                                                <Col
                                                    md="6"
                                                    style={{
                                                        textAlign: "left",
                                                        alignSelf: "center",
                                                    }}
                                                >
                                                    <span>{company.company_details.company}</span>
                                                </Col>
                                            </Row>
                                        ))}
                                    </>
                                )}
                            </>
                        }

                        <Nav vertical className="" style={{position:"absolute"}} >

                            {profile && !(profile.role === "admistrator" || profile.role === "administrateur") &&
                                <NavItem >
                                    <NavLink
                                        style={{ cursor: "pointer", padding: "0px" }}
                                        active={activeLink === "/home"}
                                        onClick={() => handleLinkClick("/home")}
                                    >
                                        <FiHome size={20} id="iconMobile" />
                                        <span id="titleMenu">HOME</span>
                                    </NavLink>
                                </NavItem>
                            }

                            {
                                permissions && permissions.some((permission: any) =>
                                    permission.module === 'DASHBOARD' && permission.tag === 'view_dashboard'
                                ) &&
                                <NavItem>
                                    <NavLink

                                        style={{ cursor: "pointer", padding: "0" }}
                                        active={activeLink === "/dashboard"}
                                        onClick={() => handleLinkClick("/dashboard")}
                                    >
                                        <IoSpeedometerOutline size={20} id="iconMobile" />
                                        <span id="titleMenu">{t("sideMenu.menuOne")}</span>
                                    </NavLink>
                                </NavItem>

                            }

                            {
                                permissions.some((permission: any) =>
                                    (permission.module === 'TRANSACTIONSCB' && permission.tag === 'view_transactions_cb') ||
                                    (permission.module === 'OPERATIONS' && permission.tag === 'view_operations')
                                ) &&
                                <NavItem>
                                    <NavLink style={{ cursor: "pointer", padding: "0" }}
                                        active={activeLink === "/payments"}
                                        onClick={() => handleLinkClick("/payments")}>
                                        <RiRepeatFill size={20} id="iconMobile" ></RiRepeatFill>
                                        <span id="titleMenu">{t('sideMenu.menuTwo.label')}</span>
                                    </NavLink>
                                </NavItem>

                            }

                            {
                                permissions.some((permission: any) =>
                                    (permission.module === 'WIRES' && permission.tag === 'view_wires') ||
                                    (permission.module === 'WIRES' && permission.tag === 'view_bank_accounts')
                                ) &&
                                <NavItem>
                                    <NavLink style={{ cursor: "pointer", padding: "0" }}
                                        active={activeLink === "/finances"}
                                        onClick={() => handleLinkClick("/finances")}>
                                        <BsBank size={20} id="iconMobile" ></BsBank>
                                        <span id="titleMenu">{t('sideMenu.menuThree.label')}</span>
                                    </NavLink>
                                </NavItem>

                            }
                            {
                                permissions && permissions.some((permission: any) =>
                                    permission.module === 'INVOICE' && permission.tag === 'view_invoices'
                                ) &&
                                <NavItem>
                                    <NavLink
                                        // href="/finance/invoices"
                                        active={activeLink === "/invoices"}
                                        onClick={() => handleLinkClick("/invoices")}
                                        className="menu-sub-item"
                                        style={{ cursor: "pointer", padding: "0" }}

                                    ><LiaFileInvoiceSolid size={20} id="iconMobile" />

                                        <span id="titleMenu">{t('sideMenu.menuThree.subMenu.0')}</span>
                                    </NavLink>
                                </NavItem>
                            }
                            {
                                permissions && permissions.some((permission: any) => (permission.module === 'PAYLINKS' && permission.tag === 'view_paylinks'))
                                && (<>
                                    <NavItem>
                                        <NavLink
                                            active={activeLink === "/paylinks-permanent"}
                                            onClick={() => handleLinkClick("/paylinks-permanent")}
                                            style={{ cursor: "pointer", padding: "0" }}

                                        >
                                            <MdInsertLink size={20} id="iconMobile" />
                                            <span id="titleMenu">Lien de paiement</span>
                                        </NavLink>
                                    </NavItem>
                                </>)
                            }
                            {
                                permissions && permissions.some((permission: any) =>
                                    (permission.module === 'PAYLINKS_ADVANCED' && permission.tag === 'view_products') ||
                                    // (permission.module === 'PAYLINKS' && permission.tag === 'view_paylinks') ||
                                    (permission.module === 'PAYLINKS_ADVANCED' && permission.tag === 'view_paylinks_checkout')
                                ) &&
                                <NavItem>
                                    <NavLink
                                        // href="/paylinks"
                                        active={activeLink === "/paylinks-checkout" || activeLink==="/paylinks-checkout/generate"}
                                        onClick={() => handleLinkClick("/paylinks-checkout")}
                                        style={{ cursor: "pointer", padding: "0" }}

                                    >
                                        <AiOutlineLink size={20} id="iconMobile" />
                                        <span id="titleMenu">Paylink avancées</span>
                                    </NavLink>
                                </NavItem>
                            }
                            {
                                permissions && permissions.some((permission: any) =>
                                    (permission.module === 'QRCODES' && permission.tag === 'view_qrcodes')
                                    // || (permission.module === 'QRCODES' && permission.tag === 'view_qods')
                                ) &&
                                <NavItem>
                                    <NavLink
                                        active={activeLink === "/qrcodes/tpe"}
                                        onClick={() => handleLinkClick("/qrcodes/tpe")}
                                        style={{ cursor: "pointer", padding: "0" }}

                                    >
                                        <HiQrCode size={20} id="iconMobile" />
                                        <span id="titleMenu">{t('sideMenu.menuFive.subMenu.0')}</span>

                                    </NavLink>
                                </NavItem>
                            }
                            {
                                permissions && permissions.some((permission: any) =>
                                    permission.module === 'DEVELOPER_INTEGRATIONS' && permission.tag === 'view_integrations'
                                ) &&
                                <NavItem>
                                    <NavLink
                                        // href="/for-developers"
                                        active={activeLink === "/for-developers"}
                                        onClick={() => handleLinkClick("/for-developers")}
                                        style={{ cursor: "pointer", padding: "0" }}
                                    >
                                        <HiOutlineComputerDesktop size={20} id="iconMobile" />
                                        <span id="titleMenu">{t('sideMenu.menuSix')}</span>
                                    </NavLink>
                                </NavItem>
                            }
                            {/* <NavItem>
                    <NavLink
                        href="/delivery-space"
                        active={activeLink === "/delivery-space"}
                        onClick={() => handleLinkClick("/delivery-space")}
                    >
                        <CiDeliveryTruck size={20} style={{ margin: "12px",color:"black" }} />
                        Espace Livraison
                    </NavLink>
                </NavItem> */}
                            <NavItem
                                style={{
                                    position: "fixed",
                                    bottom: "1rem",
                                }}
                            >
                                <NavLink
                                    active={activeLink === "/settings"}
                                    onClick={() => handleLinkClick("/settings")}
                                    style={{ cursor: "pointer", padding: "0px", width: "230px" }}
                                >
                                    <FaRegSun id="iconMobile" />
                                    <span id="titleMenu">{t("sideMenu.menuSeven")}</span>

                                </NavLink>
                            </NavItem>
                        </Nav>
                    </>

            }

        </aside >
    )
}

export default SideDrawer