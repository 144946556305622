import { Layout as DashboardLayout } from "../containers/Layout";
import React, { startTransition, useContext, useEffect, useState } from "react";
import useWebSocket from 'react-use-websocket';
import { ReadyState } from 'react-use-websocket';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProfile } from '../redux/slices/profileSlice';
import { AnyARecord } from "dns";

function DashboardPage() {
    const profile = useSelector((state: any) => state.profile);

    const socketUrl = 'ws://127.0.0.1:8010/ws/notifications/';
    //const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl);

    /* const handleData = () => {
        if (lastMessage !== null) {
            const message = JSON.parse(lastMessage.data);
            // Process incoming real-time data
        }
    }; */

    /* if (!profile) {
        return <div>Loading profile and user info...</div>;
    } else {
        return <div>{profile.aimed_revenue}</div>
    } */

    return (
        <>
            {/* <DashboardLayout>
                <div>
                    WebSocket Status: {ReadyState[readyState]}
                </div>
                <div>
                    Last Message: {lastMessage ? lastMessage.data : 'No messages yet'}
                </div>
                {readyState === ReadyState.OPEN && (
                    <button onClick={() => sendMessage(JSON.stringify({ message: 'Hello' }))}>
                        Send Message
                    </button>
                )}
                <button onClick={() => sendMessage('Hello frowm React!')}>
                    Send Message
                </button>
            </DashboardLayout> */}
        </>
    )
}

export default DashboardPage;