import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const SideDrawerSkeleton: React.FC = () => {
    return (
        <div style={{ width: '250px', padding: '20px' }}>
            <div style={{ marginTop: '20px' }}>
                {Array.from({ length: 5 }).map((_, index) => (
                    <div key={index} style={{ marginBottom: '10px' }}>
                        <Skeleton height={20} />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default SideDrawerSkeleton;