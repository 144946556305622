import React, { useState } from "react";
import { BsCheck } from "react-icons/bs";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import "../../../scss/components/form/input.css";
import { Progress } from "reactstrap";
import { MdErrorOutline } from "react-icons/md";

interface InputProps {
  label?: string;
  name: string;
  type?: string;
  value?: string;
  placeholder?: string;
  error?: boolean;
  errorMessage?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;

  classname?: string;
  style?: React.CSSProperties;
  disabled?: boolean;
  maxLength?: number;
  withPasswordMeter?: boolean;
  minDate?: string;
}
const PasswordStrengthMeter = ({ password }: any) => {
  const minLength = 8;
  let strength = 0;

  const strengthLevels = [
    { strength: 1, label: "Trés Faible", color: "secondary" },
    { strength: 2, label: "Faible", color: "danger" },
    { strength: 3, label: "Intermédiaire", color: "warning" },
    { strength: 4, label: "Intermédiaire", color: "warning" },
    { strength: 5, label: "Fort", color: "success" },
  ];

  if (password.length >= minLength) strength += 1;

  if (password.match(/(?=.*[0-9])/)) strength += 1;

  if (password.match(/(?=.*[!,%,&,@,#,$,^,*,?,_,~,<,>,])/)) strength += 1;

  if (password.match(/(?=.*[a-z])/)) strength += 1;

  if (password.match(/(?=.*[A-Z])/)) strength += 1;

  const progress = (strength / strengthLevels.length) * 100;
  const level =
    strengthLevels.find((level) => level.strength === strength) ||
    strengthLevels[0];

  return (
    <>
      <Progress value={progress} color={level.color} style={{ height: "15px" }} >
        <span style={{ color: "white" }}>{level.label}</span>
      </Progress>
    </>
  );
};
const PInput: React.FC<InputProps> = ({
  label,
  name,
  type = "text",
  value = "",
  placeholder = "",
  error = false,
  errorMessage,
  onChange,
  classname = "",
  style,
  disabled = false,
  maxLength,
  withPasswordMeter = false,
  minDate

}) => {
  const [inputValue, setInputValue] = useState(value);
  const [showPassword, setShowPassword] = useState(false);
  const [lengthTextInput, setLengthTextInput] = useState(0);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (type === "tel")
      event.target.value = event.target.value.replace(/[^0-9]/g, "");
    setInputValue(event.target.value);
    if (onChange) {
      onChange(event);
      if (withPasswordMeter) {
        setLengthTextInput(value.length);
      }
    }


  };

  const minDateValue = minDate || new Date().toISOString().split('T')[0];

  return (
    <>
      <input
        name={name}
        type={showPassword ? "text" : type}
        value={inputValue}
        placeholder={placeholder}
        onChange={handleChange}
        className={`px-2 py-2 w-100 ${classname}`}
        inputMode={type === "tel" ? "numeric" : "text"}
        pattern={type === "tel" ? "[0-9]*" : undefined}
        disabled={disabled}
        maxLength={maxLength}
        min={type === 'date' ? minDateValue : undefined}
      />
      {type != "password" ? (
        classname.includes("has-success") && (
          <BsCheck size={15} className="input-valid-icon" color="#0E6245" />
        )
      ) : showPassword ? (
        <FaRegEyeSlash
          className={error ? "fa-eye-error" : "fa-eye"}
          onClick={() => setShowPassword(!showPassword)}
        />
      ) : (
        <FaRegEye
          className={error ? "fa-eye-error" : "fa-eye"}
          onClick={() => setShowPassword(!showPassword)}
        />
      )}
      {type === "password" && lengthTextInput > 2 && withPasswordMeter && (
        <div style={{ marginTop: "10px" }}>
          <PasswordStrengthMeter password={inputValue} />
        </div>
      )}

      {error && (
        <>
          <p className="text-danger mt-2">
            <MdErrorOutline style={{ marginRight: "5px", marginTop: "-2px" }} />
            {errorMessage}
          </p>
        </>
      )}
    </>
  );
};

export default PInput;
