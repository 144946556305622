import { createSlice } from '@reduxjs/toolkit';

const paymentCheckoutSlice = createSlice({
    name: 'paymentCheckout',
    initialState: {
        checkoutFor: "",
        paylinkType: "",
        qrcodeType: "",
        activeTab: "1",
        additionalFields: [],
        availableProducts: [],
        availableDeliveryOption: null,

        actualStep: 1,
        actualProgress: 0,

        photoDonation: null,
        photoEvent: null,

        paylinkDetails: {
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            paid: null,
            amount: null,
            note: null,
            description: "",
            title: ""
        },
        merchantDetails: {
            firstName: "",
            lastName: "",
            email: "",
            company: "",
            logo: "",
            phoneNumber: "",
            website: ""
        },
        payerInfos: {
            firstname: "",
            lastname: "",
            email: "",
            phone: "",
            commentary: ""
        },

        checkoutOrder: {
            resultedAmount: 0,
            products: [],
            delivery: null,
            extras: []
        },

        checkoutFormErrors: {
            firstname: "",
            lastname: "",
            email: "",
            phone: "",
            commentary: ""
        },
    },
    reducers: {
        calculateTotalAmountProduct(state) {
            let totalAmount = state.checkoutOrder.products.reduce((total, checkoutProduct) => {

                const fullProduct = state.availableProducts.find(p => p.id === checkoutProduct.product_id);

                if (fullProduct) {
                    let productTotal = fullProduct.init_price * checkoutProduct.quantity;
                    let optionsTotal = 0;

                    if (Array.isArray(checkoutProduct.options) && checkoutProduct.options.length > 0) {
                        JSON.parse(JSON.stringify(checkoutProduct.options)).map((option, index) => {
                            if (state.paylinkType == "event") {
                                optionsTotal += parseInt(option.price) * option.quantity;
                            } else {
                                optionsTotal += parseInt(option.price) * checkoutProduct.quantity;
                            }
                        })
                    }

                    productTotal += optionsTotal;
                    total += productTotal;
                }

                return total;
            }, 0);

            if (state.checkoutOrder.delivery) totalAmount += parseInt(state.availableDeliveryOption.options[0].option_values.find(ov => ov.id == parseInt(state.checkoutOrder.delivery)).price);
            state.checkoutOrder.resultedAmount = totalAmount;
        },

        calculateTotalAmountEvent(state) {
            const productTotal = state.checkoutOrder.products.reduce((total, event) => {
                return total + (event.price * event.quantity);
            }, 0);

            const extrasTotal = state.checkoutOrder.extras.reduce((total, extra) => {
                return total + (extra.price * extra.quantity);
            }, 0);

            state.checkoutOrder.resultedAmount = productTotal + extrasTotal;
        },

        calculatedCheckoutProductPrice: (state, product_index) => {
            const checkoutProd = state.checkoutOrder.products[product_index];

            if (checkoutProd) {
                const { product_id, quantity, options, price } = checkoutProd;
                const product = state.availableProducts.find(prod => prod.id == product_id);
                if (product) {
                    let productPrice = product.init_price;

                    options.forEach(opt => {
                        const productOption = product.options.find(option => option.id === opt.option_id);

                        if (productOption) {
                            const optionValue = productOption.option_values.find(val => val.id === opt.option_value_id);

                            if (optionValue) {
                                productPrice += parseFloat(optionValue.price);
                            }
                        }
                    });

                    checkoutProd.price = productPrice;
                }

            }
        },

        updateChekoutFor: (state, action) => {
            state.checkoutFor = action.payload;
        },
        updateChekoutType: (state, action) => {
            state.paylinkType = action.payload;
        },
        updateActiveTab: (state, action) => {
            state.activeTab = action.payload;
        },
        updateMerchantDetails(state, action) {
            const { first_name: firstName, last_name: lastName, email, company, logo, username: phoneNumber, website } = action.payload;
            state.merchantDetails = {
                ...state.merchantDetails,
                firstName,
                lastName,
                email,
                company,
                logo,
                phoneNumber,
                website
            };
        },

        updatePaylinkDetails(state, action) {
            const { first_name: firstName, last_name: lastName, email, phone } = action.payload;
            state.merchantDetails = {
                ...state.merchantDetails,
                firstName,
                lastName,
                email,
                phone
            };
        },

        updatePaylinkDetailsField(state, action) {
            const { key, value } = action.payload;
            state.paylinkDetails[key] = value;
        },

        initAvailableProducts(state, action) {
            let resultedAmount = 0;
            if (state.paylinkType == "sale") {
                state.availableProducts = action.payload;
                state.checkoutOrder.products = [];
                state.availableProducts.forEach(product => {
                    const product_id = product.id;
                    const base_price = product.init_price;
                    const options_array = [];
                    let productPrice = 0;

                    if (product.options.length > 0) {
                        product.options.forEach(option => {
                            let ov_default = option.option_values[0];
                            resultedAmount += parseFloat(ov_default.price);
                            productPrice += parseFloat(ov_default.price);
                            options_array.push({ option_id: parseInt(option.id), option_value_id: parseInt(ov_default.id), price: parseFloat(ov_default.price) })
                        })
                    }
                    productPrice += base_price;
                    state.checkoutOrder.products.push({
                        product_id: product_id,
                        quantity: 0,
                        options: options_array,
                        price: productPrice
                    });
                });
                //state.checkoutOrder.resultedAmount = resultedAmount;
                state.checkoutOrder.products.sort((a, b) => a.product_id - b.product_id);
            } else if (state.paylinkType == "event") {
                state.availableProducts = action.payload;
                state.checkoutOrder.products = [];
                state.availableProducts[0].options[0].option_values.map(event => {
                    state.checkoutOrder.products.push({
                        event_id: event.id,
                        quantity: 0,
                        price: event.price
                    });

                    resultedAmount += parseFloat(event.price);

                })
                if (state.availableProducts[0].options.length > 1) {
                    state.availableProducts[0].options[1].option_values.map(extra => {
                        state.checkoutOrder.extras.push({
                            extra_id: extra.id,
                            quantity: 0,
                            price: extra.price
                        });
                    })
                }
                //state.checkoutOrder.resultedAmount = resultedAmount;
            }
        },

        initAdditionalFields(state, action) {
            state.additionalFields = action.payload;
            state.additionalFields.forEach(field => {
                const key_name = field.label.replace(/\s+/g, "_");
                if (field.field_type === "checkbox") {
                    state.payerInfos[key_name] = [];
                } else {
                    state.payerInfos[key_name] = "";
                }
                state.checkoutFormErrors[key_name] = "";
            });
        },

        addProductToCheckoutOrder: (state, action) => {
            const { p_id, p_index } = action.payload;
            let added_product = state.availableProducts.find(p => p.id === p_id);
            let checkout_product = state.checkoutOrder.products[p_index];
            const base_price = added_product.init_price;
            let productPrice = checkout_product.price;

            state.checkoutOrder.products.push({
                product_id: p_id,
                quantity: 0,
                options: checkout_product.options,
                price: productPrice
            });
            //let productss = state.checkoutOrder.products;
            //console.log(JSON.parse(JSON.stringify(productss)));
            state.checkoutOrder.products.sort((a, b) => a.product_id - b.product_id);
            paymentCheckoutSlice.caseReducers.calculateTotalAmountProduct(state);
        },

        toggleTicketToCheckoutOrder: (state, action) => {
            let product = state.availableProducts[0];
            let ticket = product.options[0].option_values.find(ov => ov.id === action.payload);
            const options_array = [];

            if (state.checkoutOrder.products.length == 0) {
                options_array.push({ option_id: parseInt(product.options[0].id), option_value_id: parseInt(ticket.id), quantity: 0, price: ticket.price })
                //console.log("ticket :", JSON.parse(JSON.stringify(ticket)));
                //console.log("ticket price : ", ticket.price);

                state.checkoutOrder.products.push({
                    product_id: product.id,
                    quantity: 1,
                    options: options_array
                });
            } else {
                const checkoutProduct = state.checkoutOrder.products[0];
                if (checkoutProduct.options.length == 1 && checkoutProduct.options[0].option_value_id === action.payload) {
                    state.checkoutOrder.products = [];
                } else {
                    if (state.checkoutOrder.products[0].options.find(op => op.option_value_id === action.payload)) {
                        let filteredOptions = checkoutProduct.options.filter(op => op.option_value_id !== parseInt(action.payload));
                        checkoutProduct.options = filteredOptions;
                    } else {
                        checkoutProduct.options.push({ option_id: parseInt(product.options[0].id), option_value_id: parseInt(ticket.id), quantity: 0, price: ticket.price })
                    }
                }
            }

            paymentCheckoutSlice.caseReducers.calculateTotalAmountProduct(state);
        },

        toggleExtraToCheckoutOrder: (state, action) => {
            let product = state.availableProducts[0];
            let ticket = product.options[1].option_values.find(ov => ov.id === action.payload);
            const options_array = [];

            if (state.checkoutOrder.products.length == 0) {
                options_array.push({ option_id: parseInt(product.options[1].id), option_value_id: parseInt(ticket.id), quantity: 1, price: ticket.price })

                state.checkoutOrder.products.push({
                    product_id: product.id,
                    quantity: 1,
                    options: options_array
                });
            } else {
                const checkoutProduct = state.checkoutOrder.products[0];
                if (checkoutProduct.options.length == 1 && checkoutProduct.options[0].option_value_id === action.payload) {
                    state.checkoutOrder.products = [];
                } else {
                    if (state.checkoutOrder.products[0].options.find(op => op.option_value_id === action.payload)) {
                        let filteredOptions = checkoutProduct.options.filter(op => op.option_value_id !== parseInt(action.payload));
                        checkoutProduct.options = filteredOptions;
                    } else {
                        checkoutProduct.options.push({ option_id: parseInt(product.options[1].id), option_value_id: parseInt(ticket.id), quantity: 1, price: ticket.price })
                    }
                }
            }

            paymentCheckoutSlice.caseReducers.calculateTotalAmountProduct(state);
        },

        updateProductQuantity: (state, action) => {
            const { productIndex, quantity } = action.payload;
            if (productIndex >= 0 && productIndex < state.checkoutOrder.products.length) {
                state.checkoutOrder.products[productIndex].quantity = quantity;
            }
            paymentCheckoutSlice.caseReducers.calculateTotalAmountProduct(state);
        },

        updateEventQuantity: (state, action) => {
            const { ov_id, qty } = action.payload;

            /* const product = state.availableProducts.find(p => p.id === p_id);
            if (product) {
                if (state.paylinkType == "event") {
                    //state.checkoutOrder.products[0].options.find(op => op.option_value_id === ov_id).quantity = qty;

                    let option_value = state.availableProducts[0].options[0].option_values.find(ov => ov.id === ov_id);

                    if (state.checkoutOrder.products.length > 0) {
                        let foundProd = state.checkoutOrder.products.find(op => op.event === ov_id)
                        if (foundProd) {
                            foundProd.quantity = qty;
                        } else {
                            state.checkoutOrder.products.push({
                                event: ov_id,
                                quantity: 1,
                                price: option_value.price
                            });
                        }

                    } else {
                        state.checkoutOrder.products.push({
                            event: ov_id,
                            quantity: 1,
                            price: option_value.price
                        });
                    }
                } else {
                    product.options.forEach(option => {
                        const optionValue = option.option_values.find(ov => ov.id === ov_id);
                        if (optionValue) {
                            const checkoutProduct = state.checkoutOrder.products.find(cp => cp.product_id === p_id);
                            const checkoutOption = checkoutProduct.options.find(co => co.option_id === ov_id);

                            if (qty > 0) {
                                if (checkoutOption) {
                                    checkoutOption.quantity = qty;
                                } else {
                                    checkoutProduct.options.push({ option_id: ov_id, quantity: qty, price: optionValue.price });
                                }
                            } else {
                                checkoutProduct.options = checkoutProduct.options.filter(co => co.option_id !== ov_id);
                            }

                        }
                    });
                }

            }

            paymentCheckoutSlice.caseReducers.calculateTotalAmountProduct(state); */

            const updatedEvent = state.checkoutOrder.products.find(event => event.event_id == ov_id)
            if (updatedEvent) {
                updatedEvent.quantity = qty
            }

            paymentCheckoutSlice.caseReducers.calculateTotalAmountEvent(state);
        },

        updateExtraQuantity: (state, action) => {
            const { ov_id, qty } = action.payload;

            const updatedExtra = state.checkoutOrder.extras.find(extra => extra.extra_id == ov_id)

            if (updatedExtra) {
                updatedExtra.quantity = qty
            }

            paymentCheckoutSlice.caseReducers.calculateTotalAmountEvent(state);

        },

        updateOptionValue: (state, action) => {
            const { p_index, p_id, o_id, ov_id, qty } = action.payload;
            const checkoutProduct = state.checkoutOrder.products[p_index];
            const product = state.availableProducts.find(p => p.id === p_id);

            if (ov_id === "") {
                const index = checkoutProduct.options.findIndex(option => option.option_id === o_id);
                if (index !== -1) {
                    checkoutProduct.options.splice(index, 1);
                    paymentCheckoutSlice.caseReducers.calculateTotalAmountProduct(state);
                    paymentCheckoutSlice.caseReducers.calculatedCheckoutProductPrice(state, p_index);
                }
            } else {
                product.options.forEach(option => {
                    const optionValue = option.option_values.find(ov => ov.id === parseInt(ov_id));
                    if (optionValue) {
                        let optionExist = checkoutProduct.options.find(op => op.option_id == parseInt(o_id))

                        if (optionExist) {
                            optionExist.option_value_id = parseInt(ov_id);
                            //optionExist.quantity = qty;
                            optionExist.price = optionValue.price;
                        } else {
                            checkoutProduct.options.push({ option_id: parseInt(option.id), option_value_id: parseInt(ov_id), price: optionValue.price });
                        }

                        paymentCheckoutSlice.caseReducers.calculateTotalAmountProduct(state);
                        paymentCheckoutSlice.caseReducers.calculatedCheckoutProductPrice(state, p_index);
                    }
                });
            }
        },

        removeProductFromCheckoutOrder: (state, action) => {
            const { p_id, index } = action.payload;

            let productExist = state.checkoutOrder.products.find(p => p.product_id == p_id);

            if (productExist) {
                state.checkoutOrder.products.splice(index, 1);
            }
            paymentCheckoutSlice.caseReducers.calculateTotalAmountProduct(state);
        },

        setAvailableDeliveryOption: (state, action) => {
            state.availableDeliveryOption = action.payload
        },

        chooseDeliveryOption: (state, action) => {
            state.checkoutOrder.delivery = action.payload;
            if (state.checkoutOrder.delivery) {
                state.payerInfos["address"] = "";
                state.checkoutFormErrors["address"] = "";
            } else {
                delete state.payerInfos["address"];
                delete state.checkoutFormErrors["address"];
            }
            paymentCheckoutSlice.caseReducers.calculateTotalAmountProduct(state);
        },

        setCheckoutFormErrors: (state, action) => {
            const { key, value } = action.payload;
            if (key in state.checkoutFormErrors) {
                state.checkoutFormErrors[key] = value;
            }
        },

        updatePayerInfos: (state, action) => {
            state.payerInfos = { ...state.payerInfos, ...action.payload };
        },

        updataeActualStepProg: (state, action) => {
            const { step, prog } = action.payload;
            state.actualStep = step;
            state.actualProgress = prog;
        },

        savePhotoDonation(state, action) {
            state.photoDonation = action.payload;
        },

        updateResultedAmountDonation: (state, action) => {
            state.checkoutOrder.resultedAmount = action.payload
        }
    },
});

export const {
    updateChekoutFor,
    updateActiveTab,
    updateChekoutType,
    updateMerchantDetails,
    updatePaylinkDetails,
    updatePaylinkDetailsField,
    initAvailableProducts,
    addProductToCheckoutOrder,
    updateProductQuantity,
    removeProductFromCheckoutOrder,
    updateEventQuantity,
    updateExtraQuantity,
    updateOptionValue,
    initAdditionalFields,
    toggleTicketToCheckoutOrder,
    toggleExtraToCheckoutOrder,
    setAvailableDeliveryOption,
    chooseDeliveryOption,
    setCheckoutFormErrors,
    updatePayerInfos,
    updataeActualStepProg,
    savePhotoDonation,
    updateResultedAmountDonation
} = paymentCheckoutSlice.actions;
export default paymentCheckoutSlice.reducer;