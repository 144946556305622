import React, { useState } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  Button,
  Spinner,
} from "reactstrap";
import { GrClose } from "react-icons/gr";
import "../../../scss/components/ui/modal.css";
import { IoCloseOutline } from "react-icons/io5";

interface ModalType {
  isOpen: boolean;
  title?: string;
  onConfirmText: string;
  onCancelText: string;
  toggle: () => void;
  onConfirm: (param?: any) => void;
  showConfirm?: boolean;
  showCancel?: boolean;
  showLoader?: boolean;
  children?: React.ReactNode;
  error?: string;
  centered?: boolean;
  size?: "sm" | "md" | "lg";
  text?: string
  texteAlign?: "center" | "justify"
  marginRight?: "8.5%" | "2%"
  marginLeft?: "8.5%" | "2%"
  marginTop?: "0%" | "-10%" | "-7%"|"-5%"
  marginBottom? :"0%"| "3%"
}

const PModal: React.FC<ModalType> = (props: ModalType) => {
  const [loading, setLoading] = useState(false);

  const {
    isOpen,
    title,
    onConfirmText,
    onCancelText,
    toggle,
    onConfirm,
    showConfirm = true,
    showCancel = true,
    children,
    error,
    centered,
    size,
    texteAlign,
    marginRight,
    marginLeft,
    marginTop,
    marginBottom
  } = props;

  const handleConfirm = async () => {
    setLoading(true);
    await new Promise(() => {
      setTimeout(async () => {
        await onConfirm();
        setLoading(false);
      }, 500);
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      centered={centered !== undefined ? centered : true}
      fade={true}
      toggle={toggle}
      style={{
        width: size === "sm" ? "350px" : size === "md" ? "800px" : "1500px",
      }}
    >
      <ModalBody>
        <Row className="mt-2" style={{ textAlign: texteAlign }}>
          <h6
            style={{
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "600",
              fontSize: "24px",
              lineHeight: "29px",
              color: "#213A7D",
            }}
          >
            {title}
          </h6>
        </Row>
        {children}
        {loading && (
          <Spinner
            color="primary"
            style={{ margin: "0 auto", display: "flex" }}
          />
        )}
        {error && (
          <p
            className="text-danger"
            style={{ justifyContent: "center", display: "flex" }}
          >
            {error}
          </p>
        )}
      </ModalBody>
      <ModalFooter className="border-0">
        <Row style={{marginTop:marginTop,marginLeft:marginLeft,marginRight:marginRight,marginBottom:marginBottom}}>
          {showConfirm && (
           <Button
              className="button-without-icon mb-2"
              onClick={handleConfirm}
            >
              {onConfirmText}
            </Button>
          )}
          {showCancel && (
            <Button
              className="outlined-button-with-icon"
              outline
              onClick={toggle}
            >
              <IoCloseOutline className="mx-2" size={25} />
              {onCancelText}
            </Button>
          )}
        </Row>
      </ModalFooter>
    </Modal>
  );
};
export default PModal;
