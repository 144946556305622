import React, { startTransition, useEffect, useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, useRoutes, Router, useLocation, Navigate, useNavigate, RouteObject } from "react-router-dom";
import AuthRoutes from "./routes/auth";
import DashRoutes from "./routes/panoramix";
import GatewayRoutes from "./routes/gateway";
import ProtectedRoute from './routes/ProtectedRoute';
// import "@progress/kendo-theme-default/dist/all.css";
//import { ProfileContextProvider } from "./context/profile/ProfileContext";
import { RegisterContextProvider } from "./context/auth/RegisterContext";
import { AbilityContext, getAbility } from "./utils/permissions";
import toast from "react-hot-toast";
import { getAccessControlData, getAccountDetails, getMerchantsBelongTo } from "./services/api/panormix";
import { useDispatch, useSelector } from "react-redux";
import { fetchProfile } from "./redux/slices/profileSlice";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { Ability } from "@casl/ability";
import { useAuth } from "./hooks/useAuth";
//import { wrapRoutesWithAuth } from "./utils/security";
import { jwtDecode } from "jwt-decode";
import { setCompanies } from "./redux/slices/companySlice";

function App() {

  const navigate = useNavigate();
  const { logout, isAuthenticated } = useAuth();
  let expiryCheckInterval: any;

  const handleLogout = () => {
    logout();
    startTransition(() => {
      navigate("/login");
    });
  };

  function checkTokenExpiry(expiryDate: any) {
    const currentDate = new Date().getTime() / 1000;

    if (expiryDate) {
      if (currentDate > (new Date(expiryDate).getTime() / 1000)) {
        handleLogout()
      }
    }

  }
  const dispatch = useDispatch();

  const chosenCompany = useSelector((state: any) => state.company.chosenCompany);
  const profile = useSelector((state: any) => state.profile);

  const token = localStorage.getItem('token');

  useEffect(() => {
    if (profile && profile.role !== "administrator" && profile.role !== "administrateur")
      getMerchantsBelongTo()
        .then((res) => {
          const companies = res.data;
          dispatch(setCompanies(companies));
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 400) {
              console.log(err.response)
            } else if (err.response.status === 500) {
              toast.error("Request Error: Error occured while trying to login");
            }
          } else if (err.request) {
            toast.error("Network Error : no response from server");
          }
        });
  }, []);

  useEffect(() => {
    let expiryDate: any;
    if (token) {
      const decodedToken = jwtDecode(token);
      if (decodedToken.exp !== undefined && typeof decodedToken.exp === 'number') {
        expiryDate = new Date(decodedToken.exp * 1000);
      }

      const expiryCheckInterval = setInterval(() => {
        checkTokenExpiry(expiryDate);
      }, 10000);

      return () => clearInterval(expiryCheckInterval);
    }

  }, [token]);

  const wrapRoutesWithAuth = (routes: any) => {
    return routes.map((route: any) => {
      if (route.children) {
        route.children = wrapRoutesWithAuth(route.children);
      }

      const token = localStorage.getItem('token');
      if (!route.path.startsWith('/gateway') && (!route.path.startsWith("/signup")) && route.path !== "/login" && !token) {
        return {
          ...route,
          element: <Navigate to="/login" replace />
        };
      }

      return route;
    });
  };

  const authRoutes: RouteObject[] = AuthRoutes.map(route => ({ ...route }));
  /* const protectedRoutes: RouteObject[] = [
    ...DashRoutes.map(route => ({
      path: route.path,
      element: route.element
        ? <ProtectedRoute element={route.element} isAuthenticated={isAuthenticated} />
        : null
    })),

  ]; */
  //const routes = useRoutes([...authRoutes, ...protectedRoutes]);

  const routes = useRoutes([...AuthRoutes, ...DashRoutes, ...GatewayRoutes]);
  //const AuthWrappedRoutes = wrapRoutesWithAuth([...DashRoutes]);
  //const routes = useRoutes(AuthWrappedRoutes);

  return (
    <RegisterContextProvider>
      {routes}
    </RegisterContextProvider>
  );
}

export default App;
