import { Modal, ModalHeader, Row } from "reactstrap";
import React from "react";
import sucessIcon from "../../../../assets/images/successIcon.gif";
import { RiCloseFill } from "react-icons/ri";

interface Props {
    isOpen: boolean;
    toogle: () => void;
    message?: string;
}
const SuccessModal: React.FC<Props> = (props: Props) => {
    const { isOpen, toogle, message } = props
    return (
        <>
            <Modal isOpen={isOpen} toggle={toogle} centered size="md" style={{ maxWidth: "19%" }}>
                <ModalHeader style={{ borderBottom: "none" }} toggle={()=>{toogle()}} >
              
                    {/* <div style={{marginLeft:"19rem",cursor:"pointer"}} onClick={toogle}>
                    <RiCloseFill />

                    </div> */}
                </ModalHeader>
                <Row className="d-flex justify-content-center" style={{ marginTop: "-7%", marginRight: "1%" }}>
                    <div className="text-center" style={{ marginTop: "3%" }}>
                        <img src={sucessIcon} style={{ width: "90px", height: "90px" }}></img>
                    </div>
                </Row>
                <Row className="mt-2" style={{ textAlign: "center", marginBottom: "5%", marginLeft: "5%", marginRight: "5%" }}>
                    <span
                        style={{
                            fontFamily: "Inter",
                            fontStyle: "normal",
                            fontWeight: "600",
                            fontSize: "18px",
                            lineHeight: "29px",
                            color: "#00A693",
                        }}
                    >
                        {message}
                    </span>
                </Row>

            </Modal>
        </>)

}

export { SuccessModal }